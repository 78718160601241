import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { IContract } from '../../interfaces/contract/contract.model';
import { contractKeys, singleContractQueryReturn } from './queryKeyFactory';
import { useNetworkState } from 'react-use';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../components/Auth/userAtom';
import { useApi } from '../api/useApi';

export const useContractUnFavourContract = () => {
    const queryClient = useQueryClient();

    const { id: userID } = useRecoilValue(userAtom);
    const { online } = useNetworkState();

    const { postGql } = useApi();

    return useMutation(
        async (id: string) => {
            let unFavourContract: IContract | null = null;

            if (online) {
                const result = await postGql<{ unFavourContract: IContract }>(
                    gql`
              mutation ($id: ID!) {
                  unFavourContract(contractID: $id) {
                      ${singleContractQueryReturn}
                  }
              }
          `,
                    { id }
                );

                unFavourContract = result.unFavourContract;
            } else {
                let cachedContract: IContract | undefined = queryClient.getQueryData(
                    contractKeys.single(id)
                );

                if (!cachedContract) {
                    const cachedContracts = queryClient.getQueryData(
                        contractKeys.list
                    ) as IContract[];
                    cachedContract = cachedContracts.find((contract) => contract.id === id);
                }

                if (cachedContract) {
                    unFavourContract = { ...cachedContract };
                    if (unFavourContract.favoured) {
                        const index = unFavourContract.favoured.indexOf(userID);
                        if (index >= 0) {
                            unFavourContract.favoured.splice(index, 1);
                        }
                    }
                }

                if (!unFavourContract) {
                    throw new Error();
                }
            }

            const lsStateToPin = localStorage.contractsToPin
                ? (localStorage.contractsToPin as string)
                : '';

            const lsStateToUnPin = localStorage.contractsToUnPin
                ? (localStorage.contractsToUnPin as string)
                : '';
            const toPin: string[] = lsStateToPin.split(',').filter((val) => !!val);
            const toUnPin: string[] = lsStateToUnPin.split(',').filter((val) => !!val);

            const pinIndex = toPin.indexOf(id);

            if (!online) {
                if (pinIndex === -1) {
                    toUnPin.push(id);
                    localStorage.contractsToUnPin = toUnPin.join(',');
                }
            } else {
                const unPinIndex = toUnPin.indexOf(id);

                if (unPinIndex >= 0) {
                    toUnPin.splice(unPinIndex, 1);
                    localStorage.contractsToUnPin = toUnPin.join(',');
                }
            }

            if (pinIndex >= 0) {
                toPin.splice(pinIndex, 1);
                localStorage.contractsToPin = toPin.join(',');
            }

            return unFavourContract;
        },
        {
            onSuccess: (contract) => {
                queryClient.setQueryData(contractKeys.single(contract.id), contract);
                queryClient.setQueryData(contractKeys.list, (currentList?: IContract[]) => {
                    if (currentList) {
                        const tempList = [...currentList];
                        const listIndex = tempList.findIndex(
                            (listContract) => listContract.id === contract.id
                        );

                        if (listIndex >= 0 && tempList[listIndex]) {
                            tempList.splice(listIndex, 1);
                            tempList.push(contract);
                        }

                        return tempList;
                    } else {
                        return [];
                    }
                });
            },
            networkMode: 'offlineFirst',
        }
    );
};
