import { useApi } from '../api/useApi';
import { useMutation } from 'react-query';
import { ILog } from '../../interfaces/logging/logging';

export const useLogAddMany = () => {
    const { post } = useApi();

    return useMutation(async (input: ILog[]) => {
        return post(`/api/log/many`, input);
    });
};
