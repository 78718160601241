import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useStorageLoadTest = () => {
    const [runs, setRuns] = useState(0); // State to track the number of runs
    const intervalIdRef = useRef<NodeJS.Timer | null>(null); // Use ref to store the interval ID
    const runsRef = useRef(runs); // Use ref to store the current runs
    const workerRef = useRef<Worker>(); // Use ref for the worker

    // Initialize worker
    useEffect(() => {
        workerRef.current = new Worker('/webworker/loadtest.worker.js', { type: 'module' });
        workerRef.current.onmessage = () => {
            setRuns((currentRuns) => currentRuns - 1); // Decrease the runs count after each message
        };

        // Cleanup worker on component unmount
        return () => {
            if (workerRef.current) {
                workerRef.current.terminate();
            }
        };
    }, []);

    // Keep runsRef current
    useEffect(() => {
        runsRef.current = runs;
    }, [runs]);

    const isRunning = useMemo(() => {
        return runsRef.current > 0;
    }, []);

    const runsLeft = useMemo(() => {
        return runsRef.current;
    }, []);

    const loadTest = useCallback((testSize: string) => {
        if (workerRef.current && runsRef.current > 0) {
            // eslint-disable-next-line no-console
            console.log('Run...');
            workerRef.current.postMessage({ action: testSize });
        }
    }, []);

    const startLoadTest = useCallback(
        (x: number, testSize: string) => {
            setRuns(x); // Set the number of runs
            runsRef.current = x; // Also set the runsRef for immediate access

            if (intervalIdRef.current !== null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                clearInterval(intervalIdRef.current); // Clear any existing intervals
            }

            intervalIdRef.current = setInterval(() => {
                if (runsRef.current > 0) {
                    loadTest(testSize);
                } else {
                    if (intervalIdRef.current !== null) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        clearInterval(intervalIdRef.current);
                    }
                }
            }, 500);
        },
        [loadTest]
    );

    // Cleanup interval on component unmount
    useEffect(() => {
        return () => {
            if (intervalIdRef.current !== null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                clearInterval(intervalIdRef.current);
            }
        };
    }, []);

    return { startLoadTest, isRunning, runsLeft, runsRef };
};
