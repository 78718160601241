import { ISurvey } from "../interfaces/backend/contract.interface";

export interface IPhotoStructureItem {
  slotName: string;
  description: string;
  required: boolean;
}

export const photoStructureOuterDefault: IPhotoStructureItem[] = [
  {
    slotName: "Strassenschild",
    description:
      "Bei Fehlen des Straßenschildes bitte eine Alternative\n" +
      "suchen – z.B. Name einer Bushaltestation, Straßenbenennung an der\n" +
      "Briefkastenanlage, etc…",
    required: true,
  },
  {
    slotName: "StrassenfluchtRechts",
    description:
      "Ziel: Hiermit soll das Objektumfeld und die\n" +
      "Umgebungsbebauung dokumentiert werden! (90 Grad-Winkel mit Blick zum\n" +
      "Objekt)",
    required: true,
  },
  {
    slotName: "StrassenfluchtLinks",
    description:
      "Ziel: Hiermit soll das Objektumfeld und die\n" +
      "Umgebungsbebauung dokumentiert werden! (90 Grad-Winkel mit Blick zum\n" +
      "Objekt)",
    required: true,
  },
  {
    slotName: "Vorderansicht",
    description:
      "Ansicht von der Straße aus gesehen -> Komplettes Objekt!\n" +
      "Falls eine Frontalansicht nicht möglich ist, können auch eine seitliche\n" +
      "Ansicht bzw. (mind. 2-3) Teilansichten erstellt werden.",
    required: true,
  },
  {
    slotName: "HauseingangHausnummer",
    description:
      "Überprüfung Hausnummer auf dem Foto mit der\n" +
      "Hausnummer im Auftrag. Wenn Hausnummer nicht vorhanden:\n" +
      "Nachweis über Referenz-Hausnummer der Nachbargebäude und\n" +
      "Kommentar im Bericht.",
    required: true,
  },
  {
    slotName: "Hauseingang",
    description: "Ansicht des Hauseingangs.",
    required: true,
  },
  {
    slotName: "Klingelschild",
    description:
      "Fotodokumentation von Klingelschildern bitte nur bei MFH / Objekten\n" +
      "mit mehreren Einheiten, um eine Plausibilisierungsmöglichkeit der\n" +
      "Anzahl im Objekt befindlicher Einheiten zu ermöglichen.",
    required: false,
  },
  {
    slotName: "VorderansichtVonRechts",
    description: "45°-Aufnahme von Straße aus gesehen",
    required: true,
  },
  {
    slotName: "VorderansichtVonLinks",
    description: "45°-Aufnahme von Straße aus gesehen",
    required: true,
  },
  {
    slotName: "SeitenansichtRechts",
    description: "falls möglich",
    required: false,
  },
  {
    slotName: "SeitenansichtLinks",
    description: "falls möglich",
    required: false,
  },
  {
    slotName: "Rueckansicht",
    description:
      "falls möglich; Bei mehreren Rückansichten:\n" +
      "Rückansicht von rechts\n" +
      "Rückansicht von links",
    required: true,
  },
  {
    slotName: "GartenAussenanlage",
    description:
      "falls vorhanden; Reihenfolge: Terrasse, Balkone,\n" +
      "Loggien, Wintergarten, Solaranlage, Photovoltaik, Garten,\n" +
      "Außenanlage",
    required: true,
  },
  {
    slotName: "GaragenStellplaetzeCarPort",
    description:
      "falls vorhanden. Achtung! Der Platz direkt vor der\n" +
      "Garage wird nicht als Stellplatz gezählt!",
    required: true,
  },
  {
    slotName: "ZufahrtZumObjekt",
    description:
      "z.B. bei abseits gelegenen Objekten oder wenn Zugang über einen\n" +
      "Privatweg erfolgt",
    required: true,
  },
  {
    slotName: "Sonstiges",
    description: "",
    required: false,
  },
];

export const photoStructureInnerDefault: IPhotoStructureItem[] = [
  {
    slotName: "Wohnungseingang",
    description: "",
    required: true,
  },
  {
    slotName: "Flur",
    description: "",
    required: true,
  },
  {
    slotName: "Bad",
    description: "",
    required: true,
  },
  {
    slotName: "Wohnzimmer",
    description: "",
    required: true,
  },
  {
    slotName: "Kueche",
    description: "",
    required: true,
  },
  {
    slotName: "Sicherungskasten",
    description: "",
    required: true,
  },
  {
    slotName: "Arbeitszimmer",
    description: "",
    required: false,
  },
  {
    slotName: "Aufzug",
    description: "",
    required: false,
  },
  {
    slotName: "Balkon",
    description: "",
    required: false,
  },
  {
    slotName: "Garage (Innen)",
    description: "",
    required: false,
  },
  {
    slotName: "Gaeste-WC",
    description: "",
    required: false,
  },
  {
    slotName: "Gaestezimmer",
    description: "",
    required: false,
  },
  {
    slotName: "Hausanschlussraum",
    description: "",
    required: false,
  },
  {
    slotName: "Hauswirtschaftsraum",
    description: "",
    required: false,
  },
  {
    slotName: "Heizung",
    description: "",
    required: false,
  },
  {
    slotName: "Hobbyraum",
    description: "",
    required: false,
  },
  {
    slotName: "Kellerraum",
    description: "",
    required: false,
  },
  {
    slotName: "Kinderzimmer",
    description: "",
    required: false,
  },
  {
    slotName: "Loggia",
    description: "",
    required: false,
  },
  {
    slotName: "Terrasse",
    description: "",
    required: false,
  },
  {
    slotName: "Tiefgaragenstellplatz",
    description: "",
    required: false,
  },
  {
    slotName: "Treppe",
    description: "",
    required: false,
  },
  {
    slotName: "Treppenhaus",
    description: "",
    required: false,
  },
  {
    slotName: "Schlafzimmer",
    description: "",
    required: false,
  },
  {
    slotName: "Sonstiges",
    description: "",
    required: false,
  },
];
