import {
    IContractPhoto,
    IPhotoVault,
} from '../../../../../shared/interfaces/backend/protocol.interface';
import { SContractPhotoVault } from '../../BLForms/useVault';
import { useFilesystem } from '../../../hooks/useFilesystem';
import { useCallback } from 'react';
import {
    photoVaultName,
    photoVaultNameFile,
    setLocalPhotoTimestamp,
} from '../../../helper/contract';
import { IPhotoStructureItem } from '../../../../../shared/photos/photos';
import { ESurveyType } from 'src/interfaces/contract/contract.model';
import usePhotoStructure from './usePhotoStructure';
import { EFormType } from '../../../../../shared/interfaces/backend/contract.interface';
import { ISurvey } from '../../../interfaces/contract/survey.model';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { photoStateRecoil } from '../atoms/photoAtoms';
import { nanoid } from 'nanoid';
import { useLatest } from 'react-use';

export const usePhotoVault = () => {
    const { readFile, saveFile } = useFilesystem();

    const { getStructure } = usePhotoStructure();

    const [photoState, setPhotosState] = useRecoilState(photoStateRecoil);

    const photoStateLatest = useLatest(photoState);

    const loadVaultFile = useCallback(
        async ({
            contractId,
            survey,
        }: {
            contractId: string;
            survey: ISurvey;
        }): Promise<IPhotoVault | null> => {
            const fileName = photoVaultNameFile(contractId, survey);

            const result = await readFile(fileName, ['photo-vaults', contractId], {
                response: 'plain',
            }).catch(() => {});

            if (typeof result?.data === 'string' && result.data !== '') {
                const vaultFile = JSON.parse(result?.data ?? {}) as IPhotoVault;

                if (await SContractPhotoVault.isValid(vaultFile)) {
                    return vaultFile;
                }
            }

            return null;
        },
        [readFile]
    );

    const saveVaultFile = useCallback(
        ({
            contractId,
            survey,
            photos,
            cb,
            lastSavedOverwrite,
        }: {
            contractId: string;
            survey: ISurvey;
            photos: IContractPhoto[];
            cb?: (newVault: IPhotoVault) => void;
            lastSavedOverwrite?: string;
        }) => {
            // eslint-disable-next-line no-console
            console.log('saveVaultFile');

            const lastSaved = lastSavedOverwrite ?? dayjs().toISOString();

            const vault: IPhotoVault = {
                photos,
                lastSaved,
                surveyID: Number(survey.id),
            };

            // Schema Check
            return SContractPhotoVault.isValid(vault).then((valid: boolean) => {
                if (valid) {
                    const arrayBuffer = new TextEncoder().encode(JSON.stringify(vault));

                    return saveFile(
                        {
                            name: photoVaultNameFile(contractId, survey),
                            arrayBuffer,
                        },
                        ['photo-vaults', contractId],
                        {
                            onFinish: () => {
                                void setLocalPhotoTimestamp(
                                    photoVaultName(contractId, survey),
                                    lastSaved
                                ).then(() => {
                                    if (cb) {
                                        cb(vault);
                                    }
                                });
                            },
                        }
                    ).catch((e) => {
                        console.error(e);

                        toast.error('Fehler beim Speichern der Photo-Liste');
                    });
                } else {
                    console.error('PV INVALID!', vault);
                    toast.error('Photo-Liste konnte nicht gespeichert werden');
                }
            });
        },
        [saveFile]
    );

    const generateSlot = useCallback((item: IPhotoStructureItem): IContractPhoto => {
        return {
            slotName: item.slotName,
            label: item.slotName,
            timestamp: null,
            filePath: '',
            required: item.required,
            denied: false,
            description: item.description,
            uploaded: false,
            rotated: 0,
            height: 0,
            width: 0,
            patches: [],
        };
    }, []);

    const getNewVault = useCallback(
        (surveyType: ESurveyType) => {
            const newPhotoVault: IContractPhoto[] = [];

            getStructure(surveyType, EFormType.Standard)?.forEach((item) => {
                if (item.required) {
                    newPhotoVault.push(generateSlot(item));
                }
            });

            // Set actual photoVault in context
            return newPhotoVault;
        },
        [generateSlot, getStructure]
    );

    const setPhotoVaultEntries = useCallback(
        (contractId: string, survey: ISurvey, newPhotos: IContractPhoto[]) => {
            //Wenn der state vorhanden ist auch den state setzen

            if (photoStateLatest.current[survey.id]) {
                setPhotosState((photos) => {
                    const temp = { ...photos };
                    const tempPhotos = [...(temp[survey.id] ?? [])];

                    for (const newPhoto of newPhotos) {
                        const index = tempPhotos.findIndex(
                            (tempP) => tempP.slotName === newPhoto.slotName
                        );

                        if (index !== -1) {
                            tempPhotos[index] = newPhoto;
                        }
                    }

                    temp[survey.id] = tempPhotos;
                    return temp;
                });
            }
        },
        [photoStateLatest, setPhotosState]
    );

    const getInitVault = useCallback(
        (contractId: string, survey: ISurvey) => {
            return loadVaultFile({
                contractId,
                survey: survey,
            }).then(async (vaultFile) => {
                const serverForm = JSON.parse(survey.photo) as IPhotoVault;
                const serverFormValid =
                    (await SContractPhotoVault.isValid(serverForm)) && !!serverForm.photos.length;

                const localValid =
                    vaultFile &&
                    (await SContractPhotoVault.isValid(vaultFile)) &&
                    !!vaultFile.photos.length;

                const fetchLocal =
                    vaultFile &&
                    localValid &&
                    (!serverFormValid ||
                        dayjs(serverForm.lastSaved).isBefore(dayjs(vaultFile.lastSaved)));

                let initContext: IContractPhoto[];

                if (fetchLocal) {
                    // Set files from disc
                    initContext = vaultFile.photos;
                } else if (serverFormValid) {
                    // Set files from server
                    initContext = serverForm.photos;
                } else {
                    // set files default (first-time-loading ever)
                    initContext = getNewVault(survey.type);

                    //save it to a new vault
                    setPhotoVaultEntries(contractId, survey, initContext);
                }

                return initContext;
            });
        },
        [getNewVault, loadVaultFile, setPhotoVaultEntries]
    );

    const setPhotoVaultEntry = useCallback(
        (contractId: string, survey: ISurvey, newPhoto: IContractPhoto) => {
            //Wenn der state vorhanden ist auch den state setzen
            if (photoStateLatest.current[survey.id]) {
                setPhotosState((current) => {
                    const temp = { ...current };
                    const tempPhotos = [...(temp[survey.id] ?? [])];
                    const index = tempPhotos.findIndex(
                        (tempP) => tempP.slotName === newPhoto.slotName
                    );

                    if (index !== -1) {
                        tempPhotos[index] = newPhoto;
                    }

                    temp[survey.id] = tempPhotos;

                    return temp;
                });
            }
        },
        [photoStateLatest, setPhotosState]
    );

    const addSlot = useCallback(
        (options: {
            contractId: string;
            contractForm: EFormType;
            slotName: string;
            survey: ISurvey;
        }) => {
            if (options.slotName === '') return undefined;

            const newSlotName =
                options.slotName === 'Sonstiges' ? `Sonstige_${nanoid()}` : options.slotName;

            let returnValue: IContractPhoto[] = [];

            setPhotosState((current) => {
                const temp = { ...current };
                const tempPhotos = [...(temp[options.survey.id] ?? [])];
                const found = tempPhotos.find((slotItem) => {
                    return slotItem.slotName === newSlotName;
                });

                if (!found) {
                    // Doesn't exist, then add it

                    const structure = getStructure(options.survey.type, options.contractForm);

                    if (structure) {
                        const currentStructureItem = structure.find((item) => {
                            return item.slotName === options.slotName;
                        });

                        tempPhotos.push({
                            ...generateSlot({
                                slotName: newSlotName,
                                required: false,
                                description: currentStructureItem?.description ?? '',
                            }),
                            label: options.slotName,
                        });
                    }
                }

                temp[options.survey.id] = tempPhotos;

                returnValue = tempPhotos;

                return temp;
            });

            return returnValue;
        },
        [generateSlot, getStructure, setPhotosState]
    );

    const removeSlot = useCallback(
        (options: { contractId: string; photo: IContractPhoto; survey: ISurvey }) => {
            setPhotosState((current) => {
                const temp = { ...current };
                temp[options.survey.id] = [...(temp[options.survey.id] ?? [])].filter(
                    (tempPhoto) => tempPhoto.slotName !== options.photo.slotName
                );

                return temp;
            });
        },
        [setPhotosState]
    );

    return {
        removeSlot,
        addSlot,
        loadVault: loadVaultFile,
        photoState,
        getInitVault,
        setPhotoVaultEntry,
        setPhotoVaultEntries,
        saveVaultFile,
    };
};
