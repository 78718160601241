import localforage from 'localforage';
import { useCallback, useEffect, useRef } from 'react';
import { useContractUploadSurveyPhoto } from './contracts/useContractUploadSurveyPhoto';
import { useLatest, useNetworkState } from 'react-use';
import { useFilesystem } from './useFilesystem';
import { usePhotoVault } from '../components/photos/hooks/usePhotoVault';
import { ISurvey } from '../interfaces/contract/survey.model';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { reloadPhotoLineState } from '../recoil/atoms/reloadPhotoLine';
import dayjs from 'dayjs';
import { IContractPhoto } from '../../../shared/interfaces/backend/protocol.interface';
import { currentSurveyState } from '../recoil/atoms/currentSurvey';

export const lfSurveyPhotoSync = localforage.createInstance({
    version: 4,
    name: 'surveyPhotoSync',
});

export interface IPhotoForSync {
    contractId: string;
    slotName: string;
    survey: ISurvey;
    fileName: string;
    path: string[];
}

export const getPhotosForSync = async (): Promise<(IPhotoForSync | null)[]> => {
    const keys = await lfSurveyPhotoSync.keys();
    const photoPromises: Promise<IPhotoForSync | null>[] = [];
    for (const key of keys) {
        photoPromises.push(lfSurveyPhotoSync.getItem<IPhotoForSync>(key));
    }
    return Promise.all(photoPromises);
};

export const registerPhotoForSync = async (photo: IPhotoForSync) => {
    return lfSurveyPhotoSync.setItem(
        `${photo.contractId}-${photo.survey.id}-${photo.slotName}`,
        photo
    );
};

export const removePhotoFromSync = async (photo: IPhotoForSync) => {
    return lfSurveyPhotoSync.removeItem(`${photo.contractId}-${photo.survey.id}-${photo.slotName}`);
};

export const useSurveyPhotoSync = () => {
    const { mutateAsync: upload, isLoading } = useContractUploadSurveyPhoto();
    const setReloadPhotoLine = useSetRecoilState(reloadPhotoLineState);
    const { survey } = useRecoilValue(currentSurveyState);

    const { photoState, setPhotoVaultEntry, loadVault, saveVaultFile } = usePhotoVault();
    const photoStateLatest = useLatest(photoState);

    const { online } = useNetworkState();

    const { readFile } = useFilesystem();

    const fetchingNext = useRef(false);

    const tryNextImage = useCallback(() => {
        if (isLoading || fetchingNext.current || !online) {
            return;
        }

        fetchingNext.current = true;

        void getPhotosForSync()
            .then(async (registeredPhotos) => {
                if (registeredPhotos) {
                    const next = registeredPhotos.shift();
                    if (next) {
                        const file = await readFile(next.fileName, next.path);
                        if (file && file.file) {
                            await upload({
                                surveyID: next.survey.id,
                                slotName: next.slotName,
                                file: file.file,
                            })
                                .then(async () => {
                                    let photos = [
                                        ...(photoStateLatest.current[next.survey.id] ?? []),
                                    ];
                                    if (!photos.length) {
                                        const vault = await loadVault(next);
                                        if (vault && vault.photos) {
                                            photos = [...vault.photos];
                                        }
                                    }

                                    const actualSlotName = next.slotName.replace('-rotated', '');

                                    const photoIndex = photos.findIndex(
                                        (photoFromState) =>
                                            photoFromState.slotName === actualSlotName
                                    );

                                    if (photoIndex !== -1 && photos[photoIndex]) {
                                        const newPhoto = {
                                            ...(photos[photoIndex] as IContractPhoto),
                                            uploaded: true,
                                        };
                                        if (
                                            photoStateLatest.current &&
                                            photoStateLatest.current[next.survey.id] &&
                                            next.survey.id === survey.id
                                        ) {
                                            setPhotoVaultEntry(
                                                next.contractId,
                                                next.survey,
                                                newPhoto
                                            );
                                        } else {
                                            photos[photoIndex] = newPhoto;
                                            await saveVaultFile({
                                                survey: next.survey,
                                                contractId: next.contractId,
                                                photos,
                                            });
                                            setReloadPhotoLine((current) => {
                                                return {
                                                    ...current,
                                                    [next.survey.id]: dayjs().toISOString(),
                                                };
                                            });
                                        }
                                    }
                                    return removePhotoFromSync(next);
                                })
                                .catch(() => {
                                    fetchingNext.current = false;
                                });
                        } else {
                            return removePhotoFromSync(next);
                        }
                    }
                }
                fetchingNext.current = false;
            })
            .catch(() => {
                fetchingNext.current = false;
            });
    }, [
        isLoading,
        loadVault,
        online,
        photoStateLatest,
        readFile,
        saveVaultFile,
        setPhotoVaultEntry,
        setReloadPhotoLine,
        survey.id,
        upload,
    ]);

    useEffect(() => {
        const interval = setInterval(() => tryNextImage(), 1000);

        return () => clearInterval(interval);
    }, [tryNextImage]);
};
