import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { IContract } from '../../interfaces/contract/contract.model';
import { useApi } from '../api/useApi';
import { contractKeys, singleContractQueryReturn } from './queryKeyFactory';
import { useNetworkState } from 'react-use';
import { lfSurveyDateCache } from '../../interfaces/contract/lfSurveyDateCache';
import dayjsBusinessDays from 'dayjs-business-days';
import dayjs from 'dayjs';

export const useContractSaveSurveyDate = () => {
    const queryClient = useQueryClient();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    dayjs.extend(dayjsBusinessDays);

    const { postGql } = useApi();

    const { online } = useNetworkState();

    return useMutation(
        async (input: { id: string; surveyDate: string | null; deadlineSV?: string }) => {
            if (online) {
                // IMMOBILIENBESUCHER-T-412 Datum muss null sein
                if (input.surveyDate === '' || !dayjs(input.surveyDate).isValid()) {
                    input.surveyDate = null;
                }

                // Sanitize date - Always ISO!
                if (input.surveyDate) {
                    input.surveyDate = dayjs(input.surveyDate).toISOString();
                }

                if (input.surveyDate) {
                    // NOTE: No types available
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    const deadlinePlus24h = dayjs(input.surveyDate)
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                        .businessDaysAdd(1)
                        .toISOString() as string;

                    // Add 24 businesshours
                    input.deadlineSV = deadlinePlus24h;
                }

                const { saveContract } = await postGql<{ saveContract: IContract }>(
                    gql`
                        mutation ($input: ContractSaveInput!) {
                            saveContract(input: $input) {
                                ${singleContractQueryReturn}
                            }
                        }
                    `,
                    { input }
                );

                return saveContract;
            } else {
                throw new Error();
            }
        },
        {
            networkMode: 'offlineFirst',
            onSuccess: (contract) => {
                queryClient.setQueryData(contractKeys.single(contract.id), contract);
                void queryClient.setQueryData(contractKeys.list, (currentList?: IContract[]) => {
                    if (currentList) {
                        const listIndex = currentList.findIndex(
                            (listContract) => listContract.id === contract.id
                        );

                        if (listIndex && currentList[listIndex]) {
                            currentList[listIndex] = {
                                ...currentList[listIndex],
                                ...contract,
                            };
                        }

                        return currentList;
                    } else {
                        return [];
                    }
                });
            },
            onError: (error, variables) => {
                void lfSurveyDateCache
                    .getItem<{ [key: string]: string | null }>('surveyDates')
                    .then((surveyDates) => {
                        const t = { ...(surveyDates ?? {}) };
                        t[variables.id] = variables.surveyDate;
                        return lfSurveyDateCache.setItem('surveyDates', t).then(() => {
                            queryClient.setQueryData(
                                contractKeys.single(variables.id),
                                (contract?: IContract) => {
                                    if (contract) {
                                        return {
                                            ...contract,
                                            surveyDate: variables.surveyDate ?? undefined,
                                        };
                                    }
                                    return undefined;
                                }
                            );
                            void queryClient.setQueryData(
                                contractKeys.list,
                                (currentList?: IContract[]) => {
                                    if (currentList) {
                                        const listIndex = currentList.findIndex(
                                            (listContract) => listContract.id === variables.id
                                        );

                                        if (listIndex && currentList[listIndex]) {
                                            currentList[listIndex] = {
                                                ...currentList[listIndex],
                                                surveyDate: variables.surveyDate ?? undefined,
                                            } as IContract;
                                        }

                                        return currentList;
                                    } else {
                                        return [];
                                    }
                                }
                            );
                        });
                    });
            },
        }
    );
};
