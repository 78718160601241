import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';

interface IProgressBar {
    id?: string;
    percentage: number;
    testId?: string;
    small?: boolean;
    coloredBar?: boolean;
}

export const ProgressBar: React.FC<IProgressBar> = (props) => {
    const { percentage, id, testId, small, coloredBar } = props;

    const height = useMemo(() => {
        return small ? 'h-4 text-[9px]' : 'h-8 text-xs';
    }, [small]);

    const bgColor = useMemo(() => {
        // Color success if below 40% and warning if above, bg-primary if below 75% and bg-danger if above
        if (coloredBar) {
            return percentage <= 40
                ? 'bg-green-800'
                : percentage <= 75
                ? 'bg-orange-400'
                : 'bg-red-600';
        }

        return 'bg-primary';
    }, [coloredBar, percentage]);

    const labelStyles = useMemo(() => {
        return !small ? 'px-2 py-1 text-xs' : 'px-2 py-[0.5px] text-[8px]';
    }, [small]);

    return (
        <>
            {percentage !== null && (
                <div className={`relative flex w-full bg-neutral-200 ${height}`}>
                    {/* progress Label */}
                    <span
                        className={`absolute flex h-full w-full items-center justify-center font-serif ${
                            percentage <= 40 ? 'text-neutral-600' : 'text-white'
                        }`}
                        data-test-id={`progressBar-${id ?? testId ?? nanoid()}`}
                    >
                        <span
                            className={`rounded-full bg-neutral-100 font-serif text-neutral-600 ${labelStyles}`}
                        >
                            {/* one two diggest after comma */}
                            {percentage.toFixed(0)}%
                        </span>
                    </span>

                    {/* progress bar */}
                    <div
                        id="progress"
                        className={`flex h-full shadow-xl transition-all duration-500 ${bgColor}`}
                        style={{
                            width: `${percentage}%`,
                        }}
                    />
                </div>
            )}
        </>
    );
};
