import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { IContract } from '../../interfaces/contract/contract.model';
import { contractKeys, singleContractQueryReturn } from './queryKeyFactory';
import { useNetworkState } from 'react-use';
import { useApi } from '../api/useApi';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../components/Auth/userAtom';

export const useContractFavourContract = () => {
    const queryClient = useQueryClient();

    const { id: userID } = useRecoilValue(userAtom);
    const { online } = useNetworkState();

    const { postGql } = useApi();

    return useMutation(
        async (id: string) => {
            let favourContract: IContract | null = null;

            if (online) {
                const result = await postGql<{ favourContract: IContract }>(
                    gql`
              mutation ($id: ID!) {
                  favourContract(contractID: $id) {
                      ${singleContractQueryReturn}
                  }
              }
          `,
                    { id }
                );
                favourContract = result.favourContract;
            } else {
                let cachedContract: IContract | undefined = queryClient.getQueryData(
                    contractKeys.single(id)
                );

                if (!cachedContract) {
                    const cachedContracts = queryClient.getQueryData(
                        contractKeys.list
                    ) as IContract[];
                    cachedContract = cachedContracts.find((contract) => contract.id === id);
                }

                if (cachedContract) {
                    favourContract = { ...cachedContract };
                    if (!favourContract.favoured) {
                        favourContract.favoured = [];
                    }

                    if (!favourContract.favoured.includes(userID)) {
                        favourContract.favoured.push(userID);
                    }
                }

                if (!favourContract) {
                    throw new Error();
                }
            }

            const lsStateToPin = localStorage.contractsToPin
                ? (localStorage.contractsToPin as string)
                : '';
            const lsStateToUnPin = localStorage.contractsToUnPin
                ? (localStorage.contractsToUnPin as string)
                : '';
            const toPin: string[] = lsStateToPin.split(',').filter((val) => !!val);
            const toUnPin: string[] = lsStateToUnPin.split(',').filter((val) => !!val);

            const unPinIndex = toUnPin.indexOf(id);

            if (!online) {
                if (unPinIndex === -1) {
                    toPin.push(id);
                    localStorage.contractsToPin = toPin.join(',');
                }
            } else {
                const pinIndex = toPin.indexOf(id);

                if (pinIndex >= 0) {
                    toPin.splice(pinIndex, 1);
                    localStorage.contractsToPin = toPin.join(',');
                }
            }

            if (unPinIndex >= 0) {
                toUnPin.splice(unPinIndex, 1);
                localStorage.contractsToUnPin = toUnPin.join(',');
            }

            return favourContract;
        },
        {
            onSuccess: (contract) => {
                queryClient.setQueryData(contractKeys.single(contract.id), contract);
                queryClient.setQueryData(contractKeys.list, (currentList?: IContract[]) => {
                    if (currentList) {
                        const tempList = [...currentList];
                        const listIndex = tempList.findIndex(
                            (listContract) => listContract.id === contract.id
                        );

                        if (listIndex >= 0 && tempList[listIndex]) {
                            tempList.splice(listIndex, 1);
                            tempList.push(contract);
                        }

                        return tempList;
                    } else {
                        return [];
                    }
                });
            },
            networkMode: 'offlineFirst',
        }
    );
};
