import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal/Modal';
import useDeviceData from '../../../hooks/useDeviceData';
import { Note } from '../Note';
import { usePermissions } from '../../../hooks/usePermissions';
import { useEnvironment } from '../../../hooks/useEnvironment';

export const CompatibilityModal: React.FC = () => {
    const [show, setShow] = useState<null | boolean>(null);

    const data = useDeviceData();

    const { isPureEK } = usePermissions();

    const { isProduction } = useEnvironment();

    useEffect(() => {
        if (data.deviceData && show === null && isProduction) {
            let isCompatible = false;

            if (data.deviceData?.client?.name.toLocaleLowerCase().includes('safari')) {
                if (
                    Number(data.deviceData.client.version) >=
                    parseFloat(process.env.NEXT_PUBLIC_MINVERSION_SAFARI as string)
                )
                    isCompatible = true;
            }

            if (data.deviceData?.client?.name.toLocaleLowerCase().includes('chrome')) {
                if (
                    Number(data.deviceData.client.version) >=
                    parseFloat(process.env.NEXT_PUBLIC_MINVERSION_CHROME as string)
                )
                    isCompatible = true;
            }

            if (data.deviceData?.client?.name.toLocaleLowerCase().includes('samsung internet')) {
                if (
                    Number(data.deviceData.client.version) >=
                    parseFloat(process.env.NEXT_PUBLIC_MINVERSION_SAMSUNGINTERNET as string)
                )
                    isCompatible = true;
            }

            if (data.deviceData?.client?.name.toLocaleLowerCase().includes('microsoft edge')) {
                if (
                    Number(data.deviceData.client.version) >=
                    parseFloat(process.env.NEXT_PUBLIC_MINVERSION_EDGE as string)
                )
                    isCompatible = true;
            }

            // TODO Test Mozilla
            if (data.deviceData?.client?.name.toLocaleLowerCase().includes('firefox')) {
                if (
                    Number(data.deviceData.client.version) >=
                    parseFloat(process.env.NEXT_PUBLIC_MINVERSION_FIREFOX as string)
                )
                    isCompatible = true;
            }

            setShow(!isCompatible);
        }
    }, [data, isProduction, show]);

    return (
        <>
            {!isPureEK && show !== null && (
                <Modal
                    show={show}
                    afterClose={() => setShow(false)}
                    title="Systemvoraussetzungen"
                    closeable={false}
                >
                    <div className="block h-8" />

                    <Note severity="warning">
                        Das aktuelle System entspricht nicht den Voraussetzungen.
                        {data.deviceData?.client?.name.toLocaleLowerCase().includes('safari') && (
                            <>
                                <br />
                                <br />
                                Browser: Safari mit Version {data.deviceData?.client?.version}
                            </>
                        )}
                        {data.deviceData?.client?.name.toLocaleLowerCase().includes('chrome') && (
                            <>
                                <br />
                                <br />
                                Browser: Chrome / Microsoft Edge mit Version{' '}
                                {data.deviceData?.client?.version}
                            </>
                        )}
                        {!data.deviceData?.client?.name.toLocaleLowerCase().includes('chrome') &&
                            !data.deviceData?.client?.name
                                .toLocaleLowerCase()
                                .includes('safari') && (
                                <>
                                    <br />
                                    <br />
                                    Browser:{data.deviceData?.client?.name} ist nicht kompatibel
                                </>
                            )}
                    </Note>

                    <h3 className="mt-2 font-serif text-primary">Systemanforderungen</h3>
                    <p className="mt-3 flex w-full text-sm">
                        iOS / MacOS mit Safari Version {process.env.NEXT_PUBLIC_MINVERSION_SAFARI}
                    </p>
                    <p className="mt-3 flex w-full text-sm">
                        Chrome Browser Version {process.env.NEXT_PUBLIC_MINVERSION_CHROME}
                    </p>
                </Modal>
            )}
        </>
    );
};
