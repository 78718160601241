import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../components/Auth/userAtom';

export const usePermissions = () => {
    const { roles } = useRecoilValue(userAtom);

    const isPureEK = useMemo(() => {
        if (!roles) return null;

        return (
            roles.some((role) => role.access.includes('ek')) &&
            !roles.some((role) => role.access.includes('admin'))
        );
    }, [roles]);

    const isPureSV = useMemo(() => {
        if (!roles) return null;

        return (
            roles.some((role) => role.access.includes('sv')) &&
            !roles.some((role) => role.access.includes('admin'))
        );
    }, [roles]);

    const isAdmin = useMemo(() => {
        if (!roles) return null;

        return roles.some((role) => role.access.includes('admin'));
    }, [roles]);

    return { isPureEK, isPureSV, roles, isAdmin };
};
