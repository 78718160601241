import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

interface INote {
    severity?: 'danger' | 'success' | 'info' | 'warning' | 'severe';
    testId?: string;
}

export const Note: React.FC<INote> = (props) => {
    const { severity, children, testId } = props;

    return (
        <>
            {severity === 'success' && (
                <div
                    className={`relative flex w-full items-center bg-green-100 p-4 text-sm text-neutral-700`}
                    data-test-id={testId ?? undefined}
                >
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-4 text-xl text-green-800" />

                    <span>{children}</span>
                </div>
            )}
            {severity === 'info' && (
                <div
                    className={`relative flex w-full items-center bg-blue-100 p-4 text-sm text-neutral-700`}
                    data-test-id={testId ?? undefined}
                >
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-4 text-xl text-blue-500" />

                    <span>{children}</span>
                </div>
            )}
            {severity === 'warning' && (
                <div
                    className={`relative flex w-full items-center bg-orange-100 p-4 text-sm text-neutral-700`}
                    data-test-id={testId ?? undefined}
                >
                    <FontAwesomeIcon icon={faWarning} className="mr-4 text-xl text-orange-500" />

                    <span>{children}</span>
                </div>
            )}
            {severity === 'danger' && (
                <div
                    className={`relative flex w-full items-center bg-red-100 p-4 text-sm text-neutral-700`}
                    data-test-id={testId ?? undefined}
                >
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-4 text-xl text-red-500"
                    />

                    <span>{children}</span>
                </div>
            )}
            {severity === 'severe' && (
                <div
                    className={`relative flex w-full items-center bg-red-700 p-4 text-sm text-white`}
                    data-test-id={testId ?? undefined}
                >
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-4 text-xl text-white"
                    />

                    <span>{children}</span>
                </div>
            )}
        </>
    );
};

Note.defaultProps = {
    severity: 'info',
};
