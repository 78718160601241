import { atom } from 'recoil';
import { v4 } from 'uuid';

interface ISidebarAtom {
    current: string;
}

export const sidebarRecoilState = atom<ISidebarAtom>({
    key: `sidebar-state/${v4()}`,
    default: {
        current: 'aufträge',
    },
});
