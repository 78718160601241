import localforage from 'localforage';

export interface ISurveyDateCache {
    [key: string]: string | null;
}

export const lfSurveyDateCache = localforage.createInstance({
    name: 'surveyDate',
    version: 4,
});
