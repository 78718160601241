import React, { useMemo, useState } from 'react';
import { SidebarMobile } from '../../SidebarMobile/SidebarMobile';
import { SceneHeader } from './SceneHeader';
import { useRouter } from 'next/router';
import { SceneAdminHeader } from './SceneAdminHeader';
import { SafariModal } from '../../SafariModal/SafariModal';

interface IScene {
    pageTitle?: string;
    fullscreen?: boolean;
    hideSidebar?: boolean;
    backRoute?: string | null;
    subHeader?: JSX.Element;
    stickySubHeader?: JSX.Element;
    testId?: string;
}

export const Scene: React.FC<IScene> = (props) => {
    const { pathname } = useRouter();

    const { testId } = props;

    const isAdminPage = useMemo(() => pathname.startsWith('/admin'), [pathname]);
    const isEkPage = useMemo(() => pathname.startsWith('/customer'), [pathname]);

    const { pageTitle, children, hideSidebar, backRoute, fullscreen, subHeader, stickySubHeader } =
        props;

    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <main
            data-test-id={testId ?? undefined}
            className="relative flex h-full min-h-screen w-full flex-col md:grid md:grid-rows-[max-content,auto]"
        >
            <SidebarMobile open={sidebarOpen} setOpen={setSidebarOpen} alwaysShow={hideSidebar} />

            {/* Header */}
            <div
                className={`sticky top-0 z-40 flex w-screen flex-col ${
                    hideSidebar ? '' : 'md:pl-64'
                }`}
            >
                {(isAdminPage || isEkPage) && (
                    <SceneAdminHeader
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        pageTitle={pageTitle}
                    />
                )}
                {!isAdminPage && !isEkPage && (
                    <SceneHeader
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        pageTitle={pageTitle}
                        backRoute={backRoute}
                    />
                )}

                {/* Sticky Version if SubHeader */}
                <div>{stickySubHeader && stickySubHeader}</div>
            </div>

            <div>
                {subHeader && subHeader}
                {/*Content*/}
                <div
                    className={`relative mx-auto flex h-full min-h-full w-screen flex-col overflow-auto ${
                        fullscreen ? '' : 'max-w-7xl'
                    }`}
                    id="scene-content"
                >
                    {children}
                </div>
            </div>

            <SafariModal />
        </main>
    );
};

Scene.defaultProps = {
    fullscreen: false,
    pageTitle: '',
    hideSidebar: false,
    backRoute: null,
};
