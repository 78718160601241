import { useCallback, useEffect, useRef } from 'react';
import { useNetworkState } from 'react-use';
import { useContractFavourContract } from './contracts/useContractFavourContract';
import { useContractUnFavourContract } from './contracts/useContractUnFavourContract';

export const useContractPinnedSync = () => {
    const { mutateAsync: pin, isLoading: pinLoading } = useContractFavourContract();
    const { mutateAsync: unPin, isLoading: unPinLoading } = useContractUnFavourContract();

    const { online } = useNetworkState();

    const fetchingNext = useRef(false);

    const tryNext = useCallback(() => {
        if (pinLoading || unPinLoading || fetchingNext.current || !online) {
            return;
        }

        fetchingNext.current = true;

        const lsStateToPin = ((localStorage.contractsToPin as string) ?? '').trim();
        const toPin: string[] = lsStateToPin.split(',');

        if (toPin.length) {
            const toDo = toPin.shift();
            if (toDo) {
                fetchingNext.current = false;
                return void pin(toDo);
            }
        }

        const lsStateToUnPin = ((localStorage.contractsToUnPin as string) ?? '').trim();
        const toUnPin: string[] = lsStateToUnPin.split(',');

        if (toUnPin.length) {
            const toDo = toUnPin.shift();
            if (toDo) {
                fetchingNext.current = false;
                return void unPin(toDo);
            }
        }

        fetchingNext.current = false;
    }, [online, pin, pinLoading, unPin, unPinLoading]);

    useEffect(() => {
        const interval = setInterval(() => tryNext(), 100);

        return () => clearInterval(interval);
    }, [tryNext]);
};
