import { useNetworkState } from 'react-use';
import { useEffect, useRef, useState } from 'react';
import { ILog, lfLoggingCache } from '../../interfaces/logging/logging';
import { useLogAddMany } from '../../hooks/logging/useLogAddMany';

export const LogSync = () => {
    const { online } = useNetworkState();

    const onlineRef = useRef<boolean>();

    const [logsToAdd, setLogsToAdd] = useState<ILog[]>([]);

    const syncing = useRef<boolean>(false);

    const { mutate: addMany } = useLogAddMany();

    useEffect(() => {
        void lfLoggingCache.getItem<ILog[]>('logsToAdd').then((logs) => {
            if (logs) {
                void lfLoggingCache.removeItem('logsToAdd').then(() => {
                    setLogsToAdd((current) => {
                        return [...current, ...logs];
                    });
                });
            }
        });
    }, []);

    useEffect(() => {
        if (online && onlineRef.current === false) {
            //switch to online
            void lfLoggingCache.getItem<ILog[]>('logsToAdd').then((logs) => {
                if (logs) {
                    void lfLoggingCache.removeItem('logsToAdd').then(() => {
                        setLogsToAdd((current) => {
                            return [...current, ...logs];
                        });
                    });
                }
            });
        }

        onlineRef.current = online;
    }, [online]);

    useEffect(() => {
        if (logsToAdd.length && !syncing.current) {
            syncing.current = true;
            addMany(logsToAdd, {
                onSuccess: () => {
                    setLogsToAdd([]);
                    syncing.current = false;
                },
            });
        }
    }, [addMany, logsToAdd]);

    return <></>;
};
