import { useNetworkState } from 'react-use';
import { useEffect, useRef, useState } from 'react';
import { ISurveyDateCache, lfSurveyDateCache } from '../../interfaces/contract/lfSurveyDateCache';
import { useContractSaveSurveyDate } from '../../hooks/contracts/useContractSaveSurveyDate';

export const SurveyDateSync = () => {
    const { online } = useNetworkState();

    const onlineRef = useRef<boolean>();

    const [contractsToSync, setContractsToSync] = useState<ISurveyDateCache>({});

    const { mutate } = useContractSaveSurveyDate();

    const syncing = useRef<boolean>(false);

    useEffect(() => {
        void lfSurveyDateCache.getItem<ISurveyDateCache>('surveyDates').then((surveyDates) => {
            if (surveyDates) {
                setContractsToSync((current) => {
                    return { ...current, ...surveyDates };
                });
            }
        });
    }, []);

    useEffect(() => {
        if (online && onlineRef.current === false) {
            //switch to online
            void lfSurveyDateCache.getItem<ISurveyDateCache>('surveyDates').then((surveyDates) => {
                if (surveyDates) {
                    setContractsToSync((current) => {
                        return { ...current, ...surveyDates };
                    });
                }
            });
        }

        onlineRef.current = online;
    }, [online]);

    useEffect(() => {
        const contractsToSyncArray = Object.entries(contractsToSync);
        if (contractsToSyncArray[0] && !syncing.current && online) {
            syncing.current = true;
            const id = contractsToSyncArray[0][0];
            const surveyDate = contractsToSyncArray[0][1];
            mutate(
                { id, surveyDate },
                {
                    onSuccess: () => {
                        void lfSurveyDateCache
                            .getItem<ISurveyDateCache>('surveyDates')
                            .then((surveyDates) => {
                                if (surveyDates) {
                                    const temp = { ...surveyDates };
                                    delete temp[id];
                                    void lfSurveyDateCache.setItem('surveyDates', temp).then(() => {
                                        syncing.current = false;
                                        setContractsToSync(temp);
                                    });
                                }
                            });
                    },
                }
            );
        }
    }, [contractsToSync, mutate, online]);

    return <></>;
};
