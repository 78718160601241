import React, { useMemo } from 'react';

interface ISkeletonLine {
    height?: 'small' | 'large' | number;
    children?: React.ReactNode;
}

export const Skeleton: React.FC<ISkeletonLine> = (props) => {
    const { height, children } = props;

    const styleHeight = useMemo((): number => {
        if (typeof height === 'number') {
            return height;
        }

        if (height === 'small') {
            return 10;
        }

        if (height === 'large') {
            return 32;
        }

        return 10;
    }, [height]);

    return (
        <div
            className={`relative flex w-full animate-pulse items-center justify-center bg-neutral-500/10`}
            style={{ height: `${styleHeight}px` }}
        >
            <span className="text-xs text-white ">{children}</span>
        </div>
    );
};

Skeleton.defaultProps = {
    height: 'small',
};
