import React from 'react';
import { useNetworkState } from 'react-use';

interface IOnlineIndicatorDisplay {}

export const OnlineIndicatorDisplay: React.FC<IOnlineIndicatorDisplay> = () => {
    const { online } = useNetworkState();

    return (
        <>
            {online && (
                <div className="flex items-center space-x-1">
                    <div className="h-2 w-2 rounded-full bg-green-500" />
                    <span className="text-[9px] text-green-500">Online</span>
                </div>
            )}
            {!online && (
                <div className="flex items-center space-x-1">
                    <div className="h-2 w-2 rounded-full bg-red-500" />
                    <span className="text-[9px] text-red-500">Offline</span>
                </div>
            )}
        </>
    );
};
