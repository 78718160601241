import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import { isIOS } from '../../helper/isSafari';
import { useEnvironment } from '../../hooks/useEnvironment';

interface ISafariModal {}

export const SafariModal: React.FC<ISafariModal> = () => {
    const [showModal, setShowModal] = useState(false);

    const { isProduction } = useEnvironment();

    useEffect(() => {
        if (isIOS() && isProduction) {
            // eslint-disable-next-line no-prototype-builtins
            if (window && window.hasOwnProperty('matchMedia')) {
                const t = !window.matchMedia('(display-mode: standalone)').matches;
                setShowModal(t);
            }
        }
    }, [isProduction]);

    return (
        <>
            {showModal && (
                <Modal title="Installation iPhone/iPad/Mac">
                    <div className="border-danger bg-danger/10 p-4 text-danger">
                        Bitte installieren Sie die App auf Ihrem Gerät. Im Folgenden finden Sie eine
                        Schritt-für-Schritt Anleitung.
                    </div>

                    <div className="Install px-4">
                        <div className={'step'}>
                            <h2>Schritt 1: Teilen Button antippen</h2>
                            <p>
                                Zuerst muss der Teilen Button angeklickt werden. Die App wird
                                praktisch mit dem Gerät geteilt. Der Button befindet sich unten in
                                der Mitte im Safari (Version 14.x) Browser.
                            </p>
                            <img
                                src="/assets/install/im_login.jpg"
                                alt={'Schritt 1: Teilen klicken'}
                            />
                        </div>

                        <div className={'step'}>
                            <h2>Schritt 2: Zum Home-Bildschirm</h2>
                            <p>
                                Als nächsten muss die App mit dem -Home-Bildschirm- geteilt werden.
                                Daneben befindet sich ein kleines +-Symbol.
                            </p>
                            <img src="/assets/install/im_share.jpg" alt={'Schritt 2: Teilen'} />
                        </div>

                        <div className={'step'}>
                            <h2>Schritt 3: App installieren</h2>
                            <p>
                                Die App wird mit dem Klick auf -Hinzufügen- installiert.
                                <br />
                                (Optional) Vorher kann noch ein Name hinterlegt werden.
                            </p>
                            <img src="/assets/install/im_add.jpg" alt={'Schritt 3: Installieren'} />
                        </div>

                        <div className={'step'}>
                            <h2>Schritt 4: Wo finde ich die App</h2>
                            <p>
                                Die App befindet sich nun auf dem Startbildschirm und ist
                                installiert. Sie kann ebenfalls wie eine App aus dem Store suchen
                                und wieder löschen bei Bedarf. (Vorsicht: Bei einer Löschung sind
                                auch die Protokolle und Bilder gelöscht!)
                            </p>

                            <img
                                src="/assets/install/im_result.jpg"
                                alt={'Schritt 4: Home-Screen'}
                            />
                        </div>

                        <div className={'step'}>
                            <h2>Schritt 5: Browser schließen</h2>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
