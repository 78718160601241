import { faMapLocation } from '@fortawesome/pro-duotone-svg-icons';

export interface ISidebarItem {
    id: string;
    label: string;
    href: string;
    target: string | null;
    icon: unknown;
    permission?: string;
}

export const SidebarUserItems: ISidebarItem[] = [
    {
        id: 'auftraege',
        label: 'Aufträge',
        href: '/',
        target: null,
        icon: '',
    },
    {
        id: 'contract-map',
        label: 'Geo-Karte',
        href: '/map',
        target: null,
        icon: faMapLocation,
    },
    {
        id: 'chat',
        label: 'Chat',
        href: '/clientchat',
        target: null,
        icon: '',
    },
    {
        id: 'reports',
        label: 'Abrechnungen',
        href: '/reports',
        target: null,
        icon: '',
    },

    {
        id: 'account',
        label: 'Profil',
        href: '/account',
        target: null,
        icon: '',
    },
    {
        id: 'downloads',
        label: 'Downloads',
        href: '/downloads',
        target: null,
        icon: '',
    },
    {
        id: 'absence',
        label: 'Abwesenheit',
        href: '/absence',
        target: null,
        icon: '',
    },
    {
        id: 'impressum',
        label: 'Impressum',
        href: '/impressum',
        target: null,
        icon: '',
    },
    {
        id: 'datenschutz',
        label: 'Datenschutz',
        href: '/datenschutz',
        target: null,
        icon: '',
    },
    {
        id: 'legitimation',
        label: 'Legitimation',
        href: '/legitimation',
        target: null,
        icon: '',
    },
    {
        id: 'admin',
        label: 'Admin',
        href: '/admin',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'forms',
        label: 'Formulare',
        href: '/forms/overview',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'logout',
        label: 'Abmelden',
        href: '/logout',
        target: null,
        icon: '',
    },
];

export const SidebarAdminItems: ISidebarItem[] = [
    {
        id: 'newContract',
        label: 'Neuer Auftrag',
        href: '/admin/contract/new',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'dashboard',
        label: 'Dashboard',
        href: '/admin/dashboard',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'auftraege',
        label: 'Auftragsliste',
        href: '/admin/',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'auftraegeExtended',
        label: 'Auftragsliste Extended',
        href: '/admin/contractsExtended',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'chat',
        label: 'Chat',
        href: '/admin/clientchat',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'adminuser',
        label: 'Benutzerverwaltung',
        href: '/admin/users',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'companies',
        label: 'Endkunden',
        href: '/admin/companies',
        target: null,
        permission: 'admin',
        icon: '',
    },
    {
        id: 'user',
        label: 'SV Ansicht',
        href: '/',
        target: null,
        icon: '',
    },
    {
        id: 'impressum',
        label: 'Impressum',
        href: '/impressum',
        target: null,
        icon: '',
    },
    {
        id: 'logout',
        label: 'Abmelden',
        href: '/logout',
        target: null,
        icon: '',
    },
];

export const SidebarEKItem: ISidebarItem[] = [
    {
        id: 'newContract',
        label: 'Neuer Auftrag',
        href: '/customer/job/new',
        target: null,
        permission: 'ek',
        icon: '',
    },
    {
        id: 'customerlist',
        label: 'Auftragsübersicht',
        href: '/customer/',
        target: null,
        permission: 'ek',
        icon: '',
    },
    {
        id: 'datenschutz',
        label: 'Datenschutz',
        href: '/datenschutz',
        target: null,
        icon: '',
    },
    {
        id: 'impressum',
        label: 'Impressum',
        href: '/impressum',
        target: null,
        icon: '',
    },
    {
        id: 'logout',
        label: 'Abmelden',
        href: '/logout',
        target: null,
        icon: '',
    },
];
