import { atom } from 'recoil';
import { v4 } from 'uuid';
import { ISurvey } from '../../interfaces/contract/survey.model';
import { ESurveyType } from '../../../../shared/interfaces/backend/contract.interface';
import { EFormType } from '../../interfaces/contract/contract.model';

interface ICurrentSurveyState {
    contractId: string;
    contractForm: EFormType;
    survey: ISurvey;
}

export const DefaultCurrentSurvey = {
    contractId: '',
    contractForm: EFormType.Standard,
    survey: {
        photo: '',
        name: '',
        id: '',
        form: '',
        type: ESurveyType.Inner,
        saved: '',
        deleted: null,
    },
};

export const currentSurveyState = atom<ICurrentSurveyState>({
    key: `current-survey-state/${v4()}`,
    default: DefaultCurrentSurvey,
});
