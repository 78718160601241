import { atom, selector } from 'recoil';
import { v4 } from 'uuid';

interface IReloadPhotoLine {
    [key: string]: string;
}

export const reloadPhotoLineState = atom<IReloadPhotoLine>({
    key: `reload-photo-lines/${v4()}`,
    default: {},
});

export const lastTimeLoaded = (surveyId: string) => {
    return selector<string>({
        key: `last-time-loaded-${surveyId}/${v4()}`, // unique ID (with respect to other atoms/selectors)
        get: ({ get }) => {
            return get(reloadPhotoLineState)[surveyId] ?? '';
        },
    });
};
