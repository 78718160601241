import React, { Fragment, useMemo } from 'react';
import { SidebarAdminItems, SidebarEKItem, SidebarUserItems } from '../../data/navigationData';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { sidebarRecoilState } from '../../recoil/atoms/sidebar';
import { useUser } from '../../hooks/user/useUser';
import { getFullName } from '../../helper/userFields';
import { usePermissions } from '../../hooks/usePermissions';
import Link from 'next/link';
import * as process from 'process';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMegaphone } from '@fortawesome/pro-duotone-svg-icons';
import { whatsNewRecoil } from '../WhatsNewModal/recoil/WhatsNewState';
import { StorageEstimateDisplay } from '../StorageEstimate/StorageEstimateDisplay';

interface ISidebarMobile {
    open: boolean;
    setOpen?: (boolean) => void;
    alwaysShow?: boolean;
}

export const SidebarMobile: React.FC<ISidebarMobile> = (props) => {
    const { open, setOpen, alwaysShow } = props;

    const setShow = useSetRecoilState(whatsNewRecoil);

    const { pathname } = useRouter();

    const [sidebar, setSidebar] = useRecoilState(sidebarRecoilState);

    const version = process.env.NEXT_PUBLIC_VERSION ?? null;

    const { isPureEK, roles } = usePermissions();

    const user = useUser(true);

    const isAdminPage = useMemo(() => pathname.startsWith('/admin'), [pathname]);

    const sideBarItems = useMemo(() => {
        if (isPureEK === undefined) return undefined;

        if (isAdminPage) {
            return SidebarAdminItems;
        } else {
            if (isPureEK) return SidebarEKItem;
            if (!isPureEK) return SidebarUserItems;
        }
    }, [isAdminPage, isPureEK]);

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className={`fixed inset-0 z-40 flex ${alwaysShow ? '' : 'md:hidden'} `}
                    onClose={() => {
                        if (setOpen !== undefined) setOpen(false);
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div
                            id="sidebar-mobile"
                            className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pb-4 pt-5"
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute right-0 top-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => {
                                            if (setOpen !== undefined) setOpen(false);
                                        }}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex flex-shrink-0 items-center px-4">
                                <img src="/assets/logoMenuHeader.svg" alt="app logo" />
                            </div>
                            <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                <nav className="flex flex-col space-y-1 px-2">
                                    {sideBarItems &&
                                        sideBarItems.map((item, index) => {
                                            if (
                                                !item.permission ||
                                                roles?.some((role) =>
                                                    role.access.includes(`${item.permission ?? ''}`)
                                                )
                                            ) {
                                                return (
                                                    <Link
                                                        passHref
                                                        href={item.href}
                                                        key={`sidebar-link-${index}`}
                                                    >
                                                        <a
                                                            id={`sidebar-${item.id}`}
                                                            className={`flex h-9 w-full items-center px-2 font-serif tracking-wider hover:bg-orange-200/30 ${
                                                                sidebar.current === item.id
                                                                    ? 'text-primary-light'
                                                                    : item.permission?.includes(
                                                                          'admin'
                                                                      )
                                                                    ? 'text-orange-300'
                                                                    : 'text-neutral-200'
                                                            }`}
                                                            onClick={() => {
                                                                setSidebar({
                                                                    ...sidebar,
                                                                    current: item.id,
                                                                });

                                                                // void router.push(item.href);
                                                            }}
                                                        >
                                                            <span>{item.label}</span>
                                                        </a>
                                                    </Link>
                                                );
                                            } else {
                                                return <div key={nanoid()} className="hidden" />;
                                            }
                                        })}

                                    {/* WhatsNew Button*/}
                                    <div className="relative mb-8 mt-8 border-t border-neutral-700 md:mt-12">
                                        <button
                                            onClick={() =>
                                                setShow(() => {
                                                    return { show: true };
                                                })
                                            }
                                            className="relative mt-4 flex flex-row gap-2 rounded-full px-4 py-2 pl-2 font-serif text-sm text-primary-light outline outline-1 outline-neutral-300"
                                        >
                                            <FontAwesomeIcon
                                                icon={faMegaphone}
                                                className="h-4 text-neutral-200"
                                            />

                                            <span>Was ist neu?</span>
                                        </button>
                                    </div>
                                </nav>
                            </div>
                            {/* Bottom Part User && Access */}
                            <div className="absolute bottom-0 left-0 flex w-full flex-col bg-gray-700 p-3 pb-2">
                                <span className="font-serif text-xs tracking-wider text-neutral-100">
                                    {user.data && getFullName(user.data)}
                                </span>

                                <div className="flex flex-row gap-1">
                                    <span className="flex gap-2 text-xs tracking-wider text-gray-500">
                                        {roles &&
                                            roles.map((item) => {
                                                return <span key={nanoid()}>{item.name}</span>;
                                            })}
                                    </span>
                                    {version && (
                                        <span className="flex gap-2 text-xs tracking-wider text-gray-500">
                                            <span> | </span>version {version ?? '-'}
                                        </span>
                                    )}
                                </div>

                                <div className="pt-2">
                                    <StorageEstimateDisplay />
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="w-14 flex-shrink-0" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

SidebarMobile.defaultProps = {
    alwaysShow: false,
};
