import { atom, selector } from 'recoil';
import { IContractPhoto } from '../../../../../shared/interfaces/backend/protocol.interface';
import { nanoid } from 'nanoid';

export const photoStateRecoil = atom<{ [surveyId: string]: IContractPhoto[] }>({
    key: `survey-photos-state/${nanoid()}`, // unique ID (with respect to other atoms/selectors)
    default: {},
});

export const getPhotos = (surveyId: string) => {
    return selector<IContractPhoto[]>({
        key: `survey-photos-${surveyId}/${nanoid()}`, // unique ID (with respect to other atoms/selectors)
        get: ({ get }): IContractPhoto[] => {
            return get(photoStateRecoil)[surveyId] ?? [];
        },
    });
};

export const getPhoto = (surveyId: string, slotName: string) => {
    return selector({
        key: `survey-photo-${surveyId}-${slotName}/${nanoid()}`, // unique ID (with respect to other atoms/selectors)
        get: ({ get }) => {
            const photos = get(photoStateRecoil)[surveyId] ?? [];

            return photos.find((photo) => photo.slotName === slotName) ?? ({} as IContractPhoto);
        },
    });
};
