import {useEffect, useState} from 'react';
import DeviceDetector, {DeviceDetectorResult} from 'device-detector-js';

/**
 * DEPRECATED! it's now saved in app context
 */
const useDeviceData = () => {
    const [deviceData, setDeviceData] = useState<DeviceDetectorResult>();

    useEffect(() => {
        const deviceDetector = new DeviceDetector();
        setDeviceData(deviceDetector.parse(navigator.userAgent));
    }, []);

    return { deviceData };
};

export default useDeviceData;
