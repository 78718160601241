import React from 'react';
import { MenuAlt2Icon } from '@heroicons/react/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { CompatibilityModal } from '../../atoms/CompatibilityModal/CompatibilityModal';

interface IScene {
    pageTitle?: string;
    sidebarOpen?: boolean;
    setSidebarOpen: (value: boolean) => void;
}

export const SceneAdminHeader: React.FC<IScene> = ({ pageTitle, setSidebarOpen }) => {
    return (
        <div className="sticky top-0 left-0 z-40 flex h-16 w-screen flex-shrink-0 bg-white shadow">
            <CompatibilityModal />

            {/* Burger Button */}
            <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset"
                onClick={() => setSidebarOpen(true)}
            >
                <MenuAlt2Icon className="h-6 w-6" />
            </button>

            <div className="flex w-full flex-row items-center pl-2 text-2xl font-semibold text-primary">
                {typeof window !== 'undefined' && window.history.length > 2 && (
                    <button
                        className="flex h-full w-10 items-center justify-center"
                        onClick={() => {
                            window.history.back();
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="md:text-md text-xl" />
                    </button>
                )}

                <div className="mx-auto font-serif text-sm uppercase tracking-wide sm:text-lg">
                    {pageTitle}
                </div>
            </div>
        </div>
    );
};
