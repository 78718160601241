import { useEffect, useState } from 'react';

interface StorageEstimate {
    usage: number;
    quota: number;
    percentageUsed?: number;
    readableUsage?: string;
    readableQuota?: string;
}

// Helper function to convert bytes to a more readable format
const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const useStorageEstimate = (): StorageEstimate | null => {
    const [storageEstimate, setStorageEstimate] = useState<StorageEstimate | null>(null);

    useEffect(() => {
        const fetchStorageEstimate = async () => {
            if ('storage' in navigator && navigator.storage.estimate) {
                const estimate = await navigator.storage.estimate();

                if (typeof estimate.usage === 'number' && typeof estimate.quota === 'number') {
                    // Calculate the percentage of the quota used.
                    const percentageUsed = (estimate.usage / estimate.quota) * 100;
                    // Convert usage and quota to a human-readable format.
                    const readableUsage = formatBytes(estimate.usage);
                    const readableQuota = formatBytes(estimate.quota);

                    setStorageEstimate({
                        usage: estimate.usage,
                        quota: estimate.quota,
                        percentageUsed,
                        readableUsage,
                        readableQuota,
                    });
                } else {
                    console.warn('Storage estimate not available.');
                    setStorageEstimate(null);
                }
            } else {
                console.error('Storage Quota API is not supported in this browser.');
                setStorageEstimate(null);
            }
        };

        // Set up an interval to fetch the storage estimate every 5 seconds
        const intervalId = setInterval(() => void fetchStorageEstimate(), 5000);

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    return storageEstimate;
};
