import { useMutation } from 'react-query';
import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';

export const useContractUploadSurveyPhoto = () => {
    const { postUpload } = useApi();

    return useMutation(async (input: { file: File; slotName: string; surveyID: string }) => {
        const { photoUpload } = await postUpload<{
            photoUpload: boolean;
        }>(
            gql`
                mutation ($surveyID: ID!, $slotName: String!, $file: Upload!) {
                    photoUpload(file: $file, surveyID: $surveyID, slotName: $slotName)
                }
            `,
            { ...input, file: null },
            { '0': ['variables.file'] },
            [input.file]
        );
        return photoUpload;
    });
};
