import React, { ReactElement, useMemo } from 'react';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { nanoid } from 'nanoid';
import Ripples from 'react-ripples';

export interface IButton {
    onClick?: () => void;
    href?: string;
    target?: string;
    additionalCss?: string;
    wrapperCss?: string;
    color?: 'primary' | 'secondary' | 'danger' | 'success' | 'disabled' | 'neutral';
    iconEnd?: IconDefinition;
    iconStart?: IconDefinition;
    iconSpin?: boolean;
    disabled?: boolean;
    alignWithFormFields?: boolean;
    longBtn?: boolean;
    testId?: string;
    iconStartOpen?: ReactElement<unknown>;
    iconEndOpen?: ReactElement<unknown>;
    isResponsive?: boolean;
}

export const Button: React.FC<IButton> = (props) => {
    const {
        children,
        additionalCss,
        wrapperCss,
        onClick,
        color,
        iconEnd,
        iconStart,
        href,
        target,
        disabled,
        iconSpin,
        testId,
        alignWithFormFields,
        iconEndOpen,
        iconStartOpen,
        isResponsive,
    } = props;

    const buttonStyles = useMemo((): string => {
        switch (color) {
            case 'primary':
                return 'bg-primary text-white hover:bg-primary-dark focus:ring-primary-dark focus:ring-2 focus:ring-offset-2 focus:ring-offset-white';
            case 'secondary':
                return 'bg-secondary text-white hover:bg-secondary-dark focus:ring-secondary-dark focus:ring-2 focus:ring-offset-2 focus:ring-offset-white';
            case 'danger':
                return 'bg-danger text-white hover:bg-danger-dark focus:ring-danger-dark focus:ring-2 focus:ring-offset-2 focus:ring-offset-white';
            case 'success':
                return 'bg-success text-white hover:bg-success-dark focus:ring-success-dark focus:ring-2 focus:ring-offset-2 focus:ring-offset-white';
            case 'disabled':
                return 'bg-neutral-200 text-neutral-400 hover:bg-neutral-300 focus:ring-neutral-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white';
        }

        // Default Neutral
        return 'bg-neutral-100 text-neutral-800 hover:bg-neutral-200 focus:ring-neutral-200 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white';
    }, [color]);

    const iconSpinStyle = useMemo(() => {
        return iconSpin ? 'animate-spin' : '';
    }, [iconSpin]);

    const buttonBody = (
        <>
            {iconStart && (
                <FontAwesomeIcon
                    icon={iconStart}
                    className={` ${iconSpinStyle}  ${
                        isResponsive ? 'text-sm xs:text-base sm:text-lg md:text-xl' : 'text-xl'
                    }`}
                    data-test-id={`${testId ?? nanoid()}-iconStart`}
                />
            )}
            {iconStartOpen && <>{iconStartOpen}</>}
            <span
                className={`grow truncate text-ellipsis whitespace-nowrap ${
                    isResponsive
                        ? 'text-xs xs:text-sm sm:text-base md:text-lg lg:text-sm'
                        : 'text-sm'
                }`}
            >
                {children}
            </span>
            {iconEnd && (
                <FontAwesomeIcon
                    icon={iconEnd}
                    className={` ${iconSpinStyle} ${
                        isResponsive
                            ? 'text-xs xs:text-sm sm:text-base md:text-lg lg:text-xl'
                            : 'text-xl'
                    }`}
                    data-test-id={`${testId ?? nanoid()}-iconEnd`}
                />
            )}
            {iconEndOpen && <>{iconEndOpen}</>}
        </>
    );

    return (
        <Ripples
            color={'rgba(0, 68, 139, 0.5)'}
            during={500}
            className={`relative h-max ${wrapperCss ?? ''} overflow-hidden rounded`}
        >
            <div className={`relative flex w-full flex-col gap-0`}>
                {alignWithFormFields && <div className="relative block h-[26px]" />}

                {onClick && !href && (
                    <button
                        onClick={() => {
                            setTimeout(() => {
                                if (onClick) onClick();
                            }, 150);
                        }}
                        role="button"
                        disabled={disabled}
                        aria-disabled={disabled}
                        data-test-id={`${testId ?? nanoid()}-button`}
                        className={`relative flex w-full flex-row items-center gap-2 rounded font-semibold tracking-wide ${buttonStyles} ${
                            additionalCss ?? ''
                        }
                     ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${
                            iconStart || iconEnd ? 'justify-between' : 'justify-center'
                        }
                    ${
                        isResponsive
                            ? 'h-6 px-2 py-1 xs:h-8 sm:h-9 sm:px-3 sm:py-1.5 sm:text-lg md:h-10 md:px-4 md:py-2.5 md:text-xl'
                            : 'h-10 px-4 py-2.5'
                    }
                    `}
                    >
                        {buttonBody}
                    </button>
                )}

                {href && (
                    <Link
                        href={href}
                        onClick={onClick}
                        target={target}
                        data-test-id={`${testId ?? nanoid()}-linkButton`}
                        className={`relative flex w-full items-center justify-center gap-2 rounded ${buttonStyles} ${
                            additionalCss ?? ''
                        }
                    ${
                        isResponsive
                            ? 'h-6 px-2 py-1 xs:h-7 sm:h-9 sm:px-3 sm:py-1.5 sm:text-lg md:h-10 md:px-4 md:py-2.5 md:text-xl'
                            : 'h-10 px-4 py-2.5'
                    }
                    `}
                    >
                        {buttonBody}
                    </Link>
                )}
            </div>
        </Ripples>
    );
};

Button.defaultProps = {
    color: 'primary',
    disabled: false,
    target: '_self',
    iconSpin: false,
    alignWithFormFields: false,
};
