import { XIcon } from '@heroicons/react/outline';
import { FunctionComponent } from 'react';

export interface IModalProps {
    closeModal?: () => void;
    title?: string;
    zIndex?: number;
}

const Modal: FunctionComponent<IModalProps> = ({ closeModal, children, title, zIndex }) => {
    return (
        <>
            <div
                data-testid="filter-modal"
                style={{ zIndex: zIndex ? zIndex : 40 }}
                className={`fixed inset-0 h-full w-full bg-gray-300 bg-opacity-50`}
            >
                <div className="flex h-full w-full justify-center text-center md:p-32 overflow-auto">
                    <div className="relative flex h-screen max-w-full flex-col bg-white text-left shadow-xl md:h-min md:rounded-xl ">
                        <div className="flex flex-row justify-between p-3">
                            <span className="w-full text-center text-xl text-primary">{title}</span>
                            {closeModal && (
                                <button
                                    data-testid="close-button"
                                    id="close-button"
                                    type="button"
                                    className="absolute top-3 right-3 text-gray-700"
                                    onClick={closeModal}
                                >
                                    <XIcon className="h-6 w-6 duration-150 ease-in-out hover:text-gray-400 active:text-gray-700" />
                                </button>
                            )}
                        </div>
                        <div className="flex flex-col overflow-auto">{children}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
