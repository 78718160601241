import { useEffect, useMemo, useState } from 'react';

export const useEnvironment = () => {
    const [hostname, setHostname] = useState('');
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setHostname(window.location.hostname);
        }
    }, []);

    const isProduction = useMemo(() => {
        return hostname.includes('app.immobilienbesucher.de');
    }, [hostname]);

    const isDevelopment = useMemo(() => {
        return hostname.includes('localhost');
    }, [hostname]);

    return { isProduction, hostname, isDevelopment };
};
