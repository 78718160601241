import localforage from 'localforage';

export enum ISeverity {
    Info = 'info',
    Error = 'error',
    Warning = 'warning',
}

export interface ILogInput {
    message: string;
    contractID?: number;
    severity?: ISeverity;
    userID?: number;
}

export interface ILog extends ILogInput {
    userID: number;
    dns_uri: string;
}

export const lfLoggingCache = localforage.createInstance({
    name: 'logging',
    version: 4,
});
