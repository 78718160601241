import { atom, selector } from 'recoil';
import { nanoid } from 'nanoid';

export interface IWhatsNew {
    show: boolean;
}

export const whatsNewRecoil = atom<IWhatsNew>({
    key: `whats-new-state/${nanoid()}`,
    default: { show: false },
});

export const whatsNewShow = selector<boolean>({
    key: `whatsnewshow`,
    get: ({ get }): boolean => {
        return get(whatsNewRecoil).show;
    },
});
