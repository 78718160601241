import React, { useCallback, useMemo } from 'react';
import { useStorageEstimate } from './hooks/useStorageEstimate';
import { ProgressBar } from '../BLForms/atoms/ProgressBar';
import { Skeleton } from '../Skeletons/Skeleton';
import { useStorageLoadTest } from './hooks/useStorageLoadTest';
import { useEnvironment } from '../../hooks/useEnvironment';
import { Button } from '../buttons/Button';
import { faDownload, faSync } from '@fortawesome/pro-duotone-svg-icons';

interface IStorageEstimateDisplay {}

export const StorageEstimateDisplay: React.FC<IStorageEstimateDisplay> = () => {
    const storageEstimate = useStorageEstimate();

    const percentage100 = useMemo(() => {
        return storageEstimate?.percentageUsed ?? 0;
    }, [storageEstimate?.percentageUsed]);

    const estimateAvailable = useMemo(() => {
        return !!('storage' in navigator && navigator.storage.estimate);
    }, []);

    const { isDevelopment } = useEnvironment();

    const { startLoadTest, isRunning, runsLeft } = useStorageLoadTest();

    const handleLoadTest = useCallback(() => {
        // startLoadTest(100, 'loadTest5MB');
        startLoadTest(100, 'loadTest100MB');
    }, [startLoadTest]);

    const flagLoadTest = false;

    return (
        <>
            {storageEstimate && (
                <div className="relative flex flex-col gap-1">
                    <label className="flex w-full flex-row text-xs text-neutral-300">
                        <span className="self-start">Speicherverbrauch</span>
                        <span className="grow self-end text-right">
                            {storageEstimate.readableUsage} / {storageEstimate.readableQuota}
                        </span>
                    </label>
                    <section className="relative flex w-full flex-col gap-4">
                        <ProgressBar percentage={percentage100} small={true} coloredBar={true} />
                        {/*<ProgressBar percentage={100} small={true} coloredBar={true} />*/}
                        {/*<ProgressBar percentage={80} small={false} coloredBar={false} />*/}
                    </section>
                </div>
            )}

            {/* Loader */}
            {estimateAvailable && !storageEstimate && (
                <Skeleton height={36}>Kalkuliere Speicher...</Skeleton>
            )}

            {isDevelopment && flagLoadTest && (
                <Button
                    onClick={handleLoadTest}
                    iconSpin={isRunning}
                    iconStart={isRunning ? faSync : faDownload}
                >
                    Loadtest {isRunning ? runsLeft : ''}
                </Button>
            )}
        </>
    );
};
